<footer class="it-footer" *ngIf="showFooter">
  <div class="it-footer-main">
    <div class="footerContainer">
      <section>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-6 pb-2 colonna">
            <h4 class="top_Title">
              {{ "FOOTER.COMPANY" | translate }}
            </h4>
            <div class="link-list-wrapper">
              <ul class="footer-list link-list clearfix">
                <li>
                  <a [routerLink]="'azienda'">{{
                    "FOOTER.COMPANY_NAME" | translate
                    }} <br> {{"FOOTER.COMPANY_DESC" | translate }}</a>
                </li>

                <br>

                <a href="https://maps.app.goo.gl/Rx1hiSMFMfWoeSbq8" target="_blank">
                  <p style="margin-bottom: 0em">
                    {{ sharedData.street }}
                  </p>
                  <p class="city">
                    {{ sharedData.city }}
                  </p>
  
                </a>
  
                <p class="city">
                  {{ sharedData.codFisc_iva }}
                </p>
  
                <p style="margin-bottom: 8px; font-weight: 100">
                  Pec:
                  <a style="font-weight: 400" href="mailto:{{ sharedData.pec }}">{{
                    sharedData.pec
                    }}</a>
                </p>
                <p style="font-weight: 100">
                  Mail:
                  <a style="font-weight: 400" href="mailto:{{ sharedData.mail }}">{{
                    sharedData.mail }}</a>
                </p>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-6 pb-2 colonna">
            <h4 class="top_Title_Risk">
              {{ "FOOTER.LEGAL_INFO" | translate }}
            </h4>
            <div class="link-list-wrapper">
              <ul class="footer-list link-list clearfix">
                <li>
                  <a [routerLink]="
                      'azienda/privacy'
                    ">
                    {{ "FOOTER.PRIVACY" | translate }}
                  </a>
                </li>

                <li>
                  <a [routerLink]="
                      'azienda/amministrazione-trasparente'
                    ">
                    {{ "FOOTER.ADMINISTRATION_TRA" | translate }}
                  </a>
                </li>

                <li>
                  <a [routerLink]="'azienda/risk-management'">
                    {{ "NAVBAR_TAB.RISK_MANAGEMENT" | translate }}
                  </a>
                </li>

                <li>
                  <a [routerLink]="'azienda/urp'">
                    {{ "FOOTER.PUBLIC_RELATIONS" | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- <div class="col-lg-4 col-md-4 col-sm-6">
            <h4 class="top_Title_Risk">
              {{ "FOOTER.ADMINISTRATION" | translate }}
            </h4>
            <div class="link-list-wrapper">
              <ul class="footer-list link-list clearfix">
                <li
                  *ngFor="let administrationNavItem of administrationNavItems"
                >
                  <a [routerLink]="administrationNavItem.path">{{
                    administrationNavItem.title | translate
                  }}</a>
                </li>
              </ul>
            </div> -->
          <!-- </div> -->

          <div class="col-lg-4 col-md-4 col-sm-6 colonna">
            <h4 class="top_Title_Risk">
              {{ "FOOTER.SERVICES" | translate }}
            </h4>
            <div class="link-list-wrapper">
              <ul class="footer-list link-list clearfix">
                <li>
                  <a [routerLink]="'prenotazione-e-contatti'">
                    {{ "FOOTER.ONLY_CUP_CORPORATE" | translate }}
                  </a>
                </li>
                <li>
                  <a href='https://sinfonia.regione.campania.it/preview/cup'>
                    {{ "FOOTER.ONLY_CUP_REGIONAL" | translate }}
                  </a>
                </li>
                <li>
                  <a href='https://sinfonia.regione.campania.it/preview/fse'>
                    {{ "FOOTER.FSE" | translate }}
                  </a>
                </li>
                <li>
                  <a [routerLink]="'azienda/comitato-etico'">
                    {{ "FOOTER.COMITATO_ET" | translate }}
                  </a>
                </li>
                <li>
                  <a [routerLink]="'azienda/risk-management/cio-comitato-infezioni-ospedaliere'">
                    {{ "FOOTER.CIO" | translate }}
                  </a>
                </li>
                <li>
                  <a href="mailto:cds@policliniconapoli.it">
                    {{ "FOOTER.RCCSPID" | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <!-- <div class="divisor"></div> -->
      <!-- <section class="py-4">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-6 pb-2">
            <h4 class="bottom_Title">
              {{ "FOOTER.CONTACTS" | translate | uppercase }}
            </h4>
            <a href="https://maps.app.goo.gl/Rx1hiSMFMfWoeSbq8" target="_blank">
              <p style="margin-bottom: 0em">
                {{ sharedData.street }}
              </p>
              <p class="city">
                {{ sharedData.city }}
              </p>

            </a>

            <p class="city">
              {{ sharedData.codFisc_iva }}
            </p>

            <p style="margin-bottom: 8px; font-weight: 600">
              Pec:
              <a href="mailto:{{ sharedData.pec }}">{{
                sharedData.pec
                }}</a>
            </p>
            <p style="font-weight: 600">
              Mail:
              <a href="mailto:{{ sharedData.mail }}" class="phone_number">{{
                sharedData.mail }}</a>
            </p>
          </div> -->

          <!-- <div class="col-lg-4 col-md-4 col-sm-6 pb-2">
            <h4 class="bottom_Title">
              {{ "FOOTER.USERS" | translate | uppercase }}
            </h4>
            <div class="link-list-wrapper">
              <ul class="footer-list link-list clearfix">
                <li>
                  <a [routerLink]="'azienda/urp'" (click)="redirectTo('urp-container')">{{ "FOOTER.PUBLIC_RELATIONS" |
                    translate }}</a>
                </li>
                <li>
                  <a [routerLink]="'prenotazione-e-contatti'" (click)="redirectTo('book-visit')">{{
                    "FOOTER.PRENOTATIONS" | translate }}</a>
                </li>
                <li>
                  <a [routerLink]="'prenotazione-e-contatti'">{{
                    "FOOTER.PRESS_OFFICE" | translate
                    }}</a>
                </li>
              </ul>
            </div>
          </div> -->

          <!-- <div class="col lastCol">
            <div>
              <h4 class="bottom_Title">{{ "FOOTER.FOLLOW_US" | translate }}</h4>
              <ul style="display: inline" class="list-inline text-left social" style="display: flex">
                <li class="list-inline-item">
                  <a class="p-2 text-white" href="{{ sharedData.facebook_Link }}" target="_blank">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-facebook"
                      classes="icon icon-md icon-white align-top">
                    </svg-icon-sprite>
                    <span class="sr-only">{{
                      "FOOTER.DESIGNERS_ITALY" | translate
                      }}</span>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="p-2 text-white" href="{{ sharedData.youtube_Link }}" target="_blank">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-youtube"
                      classes="icon icon-md icon-white align-top">
                    </svg-icon-sprite>
                    <span class="sr-only">{{
                      "FOOTER.TWITTER" | translate
                      }}</span>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="p-2 text-white" href="{{ sharedData.twitter_Link }}" target="_blank">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-twitter"
                      classes="icon icon-md icon-white align-top">
                    </svg-icon-sprite>
                    <span class="sr-only">{{
                      "FOOTER.MEDIUM" | translate
                      }}</span>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="p-2 text-white" href="{{ sharedData.instagram_Link }}" target="_blank">
                    <svg-icon-sprite src="../../../assets/sprite.svg#it-instagram"
                      classes="icon icon-md icon-white align-top">
                    </svg-icon-sprite>
                    <span class="sr-only">{{
                      "FOOTER.BEHANCE" | translate
                      }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      <!-- </section> -->
    </div>
  </div>
  <div class="it-footer-small-prints clearfix dark-footer">
    <div class="container">
      <h3 class="sr-only">{{ "FOOTER.USEFUL_LINKS" | translate }}</h3>
      <ul class="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
        <li class="list-inline-item">
          <a [routerLink]="'azienda/note-legali'" title="Note Legali">{{
            "FOOTER.LEGAL_NOTES" | translate
            }}</a>
        </li>
        <li class="list-inline-item">
          <a [routerLink]="'privacy-cookie-policy'" title="Privacy-Cookies">{{
            "FOOTER.PRIVACY_POLICY" | translate
            }}</a>
        </li>
      </ul>
    </div>
  </div>
</footer>